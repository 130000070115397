import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApplicationLanguages } from '../enums/lang';
// import { ErrorPopupService } from '../services/error-popup.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../services/helpers/Loader.Service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class InterceptorsService implements HttpInterceptor {
  applicationLang = ApplicationLanguages;
  constructor(
    private loaderService: LoaderService,
    private toaster: ToastrService,
    private translate: TranslateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers
        .set('Accept', 'application/json')
        .set(
          'Accept-Language',
          localStorage.getItem('lang') == this.applicationLang.Ar
            ? this.applicationLang.Ar
            : this.applicationLang.En
        )
        .set('apikey', environment.apikey)
        .set('appcode', environment.appcode)
        .set(
          'Authorization',
          `Basic ${btoa(environment.username + ':' + environment.password)}`
        )
        .set('platformTypeId', '1'),
    });

    const storedToken = localStorage.getItem('token');

    if (storedToken && !req.url.includes('token/init')) {
      req = req.clone({ headers: req.headers.set('token', storedToken) });
    }

    this.loaderService.show();

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.toaster.error('', this.translate.instant('session-expired'));

          return throwError(
            () => new Error(this.translate.instant('session-expired'))
          );
        }

        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }
}
