import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxDropdownConfig } from 'ngx-select-dropdown';
import { ToastrService } from 'ngx-toastr';
import { brandTypesIds } from 'src/app/enums/brandTypesIds.enum';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { BePartnerService } from 'src/app/pages/partner/services/be-partner.service';
import { TokenInitService } from 'src/app/services/token-init.service';

@Component({
  selector: 'app-all-steps-in-one-partner-form',
  templateUrl: './be-partner-form-all-steps-in-one-form.component.html',
  styleUrls: ['./be-partner-form-all-steps-in-one-form.component.scss'],
})
export class AllStepsInOnePartnerFormComponent {
  @ViewChild('dateOfBirth') dateOfBirth!: ElementRef;
  @ViewChild('activityStartDate') activityStartDate!: ElementRef;

  bePartnerForm?: FormGroup;
  maxBirthDate: Date = new Date();
  loading = false;
  showSuccessModal = false;

  protected readonly brandTypesIds = brandTypesIds;
  protected dropdownConfig: NgxDropdownConfig = {};

  protected brandTypes: any = [
    { id: this.brandTypesIds.ONLINE, name: 'online-store' },
    { id: this.brandTypesIds.OFFLINE, name: 'offline' },
    {
      id: this.brandTypesIds.BOTH,
      name: 'both',
    },
  ];

  private readonly alphanumericRegex =
    '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$]*$';
  private readonly arEnCharsWithSpecialCharsRegex =
    '^[a-zA-Z\u0600-\u065F\u066E-\u06FF !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$';
  private readonly numberRegex = '^[0-9]*$';
  private readonly urlRegex =
    '^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$';

  get controls() {
    return this.bePartnerForm?.controls;
  }

  get isOnlineBrand() {
    return (
      this.controls?.['brandType']?.value?.id === this.brandTypesIds.ONLINE
    );
  }

  get isOfflineBrand() {
    return (
      this.controls?.['brandType']?.value?.id === this.brandTypesIds.OFFLINE
    );
  }

  get isOnlineAndOfflineBrand() {
    return this.controls?.['brandType']?.value?.id === this.brandTypesIds.BOTH;
  }

  constructor(
    private readonly bePartnerService: BePartnerService,
    private readonly toaster: ToastrService,
    private readonly translate: TranslateService,
    private readonly tokenInitService: TokenInitService
  ) {
    this.initForm();
    this.maxBirthDate.setFullYear(this.maxBirthDate.getFullYear() - 18);
  }

  ngOnInit() {
    this.setDropdownConfig();
    this.setFieldsRequiredBasedOnBrandType();
  }

  setDropdownConfig() {
    this.dropdownConfig = {
      displayKey: 'name',
      displayFn: (item: any) => this.translate.instant(item.name),
      placeholder:
        this.translate.currentLang === ApplicationLanguages.Ar
          ? 'نوع العلامة التجارية *'
          : 'Brand Type *',
    };
  }

  private initForm() {
    this.initiateBePartnerForm();
  }

  private initiateBePartnerForm() {
    this.bePartnerForm = new FormGroup({
      merchantName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.alphanumericRegex),
      ]),
      brandName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(this.arEnCharsWithSpecialCharsRegex),
      ]),
      brandType: new FormControl(null, [Validators.required]),
      numberOfBranches: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.numberRegex),
      ]),
      commercialRegistrationNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15),
        Validators.pattern('^[0-9]*$'),
      ]),
      sector: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      salesVolume: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(10),
        Validators.pattern(this.numberRegex),
      ]),
      averageSales: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(this.numberRegex),
      ]),
      activityStartDate: new FormControl(null, [
        Validators.required,
        this.futureDateValidator(),
      ]),
      numberOfEmployees: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.numberRegex),
      ]),
      numberOfSuppliers: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.numberRegex),
      ]),
      expectedSalesVolume: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.numberRegex),
      ]),
      websiteLink: new FormControl('', [
        Validators.required,
        Validators.pattern(this.urlRegex),
      ]),

      facebookLink: new FormControl('', [
        Validators.required,
        Validators.pattern(this.urlRegex),
      ]),
      instagramLink: new FormControl('', [
        Validators.required,
        Validators.pattern(this.urlRegex),
      ]),
      xLink: new FormControl('', [
        Validators.required,
        Validators.pattern(this.urlRegex),
      ]),

      relationshipToTheStore: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(
          '^[a-zA-Z\u0621-\u064A !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$'
        ),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(
          '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$'
        ),
      ]),
      idNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^[12][0-9]{9}$'),
      ]),
      dateOfBirth: new FormControl(null, [
        Validators.required,
        this.minimumAgeValidator(18),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^5[0-9]{8}$'),
      ]),

      commercialRegistration: new FormControl(null, [Validators.required]),
      vatCertificate: new FormControl(null, [Validators.required]),
      merchantBankAccount: new FormControl(null, [Validators.required]),
      signatoriesPersonalId: new FormControl(null, [Validators.required]),
      municipalityLicense: new FormControl(null, [Validators.required]),
      eCommerceAuthenticationCertificate: new FormControl(null, [
        Validators.required,
      ]),
    });
  }

  private setFieldsRequiredBasedOnBrandType() {
    this.bePartnerForm?.get('brandType')?.valueChanges.subscribe((value) => {
      const controls = {
        numberOfBranches: this.bePartnerForm?.get('numberOfBranches'),
        websiteLink: this.bePartnerForm?.get('websiteLink'),
        facebookLink: this.bePartnerForm?.get('facebookLink'),
        instagramLink: this.bePartnerForm?.get('instagramLink'),
        xLink: this.bePartnerForm?.get('xLink'),
        municipalityLicense: this.bePartnerForm?.get('municipalityLicense'),
        eCommerceAuth: this.bePartnerForm?.get(
          'eCommerceAuthenticationCertificate'
        ),
      };

      const socialMediaValidators = [
        Validators.required,
        Validators.pattern(this.urlRegex),
      ];
      const numberValidator = [Validators.pattern(this.numberRegex)];
      const requiredNumberValidator = [...numberValidator, Validators.required];

      switch (value?.id) {
        case this.brandTypesIds.ONLINE:
          this.setOnlineValidators(
            controls,
            socialMediaValidators,
            numberValidator
          );
          break;
        case this.brandTypesIds.OFFLINE:
          this.setOfflineValidators(controls, requiredNumberValidator);
          break;
        case this.brandTypesIds.BOTH:
          this.setBothValidators(
            controls,
            socialMediaValidators,
            requiredNumberValidator
          );
          break;
      }

      Object.values(controls).forEach((control) =>
        control?.updateValueAndValidity()
      );
    });
  }

  private setOnlineValidators(
    controls: any,
    socialValidators: any[],
    numberValidator: any[]
  ) {
    controls.numberOfBranches?.setValidators(numberValidator);
    controls.municipalityLicense?.clearValidators();
    controls.eCommerceAuth?.setValidators([Validators.required]);
    this.setSocialMediaValidators(controls, socialValidators);
  }

  private setOfflineValidators(controls: any, requiredNumberValidator: any[]) {
    controls.numberOfBranches?.setValidators(requiredNumberValidator);
    controls.municipalityLicense?.setValidators([Validators.required]);
    this.clearSocialMediaValidators(controls);
    controls.eCommerceAuth?.clearValidators();
  }

  private setBothValidators(
    controls: any,
    socialValidators: any[],
    requiredNumberValidator: any[]
  ) {
    controls.numberOfBranches?.setValidators(requiredNumberValidator);
    controls.municipalityLicense?.setValidators([Validators.required]);
    controls.eCommerceAuth?.setValidators([Validators.required]);
    this.setSocialMediaValidators(controls, socialValidators);
  }

  private setSocialMediaValidators(controls: any, validators: any[]) {
    ['websiteLink', 'facebookLink', 'instagramLink', 'xLink'].forEach(
      (field) => {
        controls[field]?.setValidators(validators);
      }
    );
  }

  private clearSocialMediaValidators(controls: any) {
    ['websiteLink', 'facebookLink', 'instagramLink', 'xLink'].forEach(
      (field) => {
        controls[field]?.clearValidators();
      }
    );
  }

  minimumAgeValidator(minAge: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate = new Date(control.value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age >= minAge
        ? null
        : { minimumAge: { requiredAge: minAge, actualAge: age } };
    };
  }

  private futureDateValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputDate = new Date(control.value);
      inputDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return inputDate <= today ? null : { futureDate: { value: inputDate } };
    };
  }

  bePartnerFormMethod() {
    this.loading = true;

    this.tokenInitService.tokenInit().subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.data.token);

        this.bePartnerService
          .bePartner({
            ...this.bePartnerForm?.value,
            brandType: this.bePartnerForm?.get('brandType')?.value?.id,
          })
          .subscribe({
            next: () => {
              this.handleSuccess();
            },
            error: (error) => {
              this.loading = false;
              this.handleError(error);
            },
          });
      },
    });
  }

  private handleSuccess() {
    this.loading = false;
    this.openSuccessModal();

    this.resetForm();
  }

  private resetForm() {
    this.bePartnerForm?.reset();
    this.dateOfBirth.nativeElement.value = null;
    this.activityStartDate.nativeElement.value = null;
  }

  private handleError(error: any) {
    if (error?.error?.errors) {
      const errors = error.error.errors;
      Object.keys(errors).forEach((key) => {
        const errorMessage = `${key}: ${errors[key].join(', ')}`;
        this.toaster.error(
          errorMessage,
          this.translate.instant('validation-error')
        );
      });
    } else {
      const errorMessage = error?.error?.detail || error?.error?.message;

      if (errorMessage) {
        this.toaster.error(
          errorMessage,
          this.translate.instant('something-went-wrong')
        );
      }
    }
  }

  openSuccessModal() {
    this.showSuccessModal = true;
    document.body.classList.add('no-scroll');
  }
}
