import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BePartnerService } from 'src/app/pages/partner/services/be-partner.service';
import { TokenInitService } from 'src/app/services/token-init.service';

@Component({
  selector: 'app-partner-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class PartnerFormComponent {
  @Output() openModal: EventEmitter<void> = new EventEmitter<void>();

  partnerForm?: FormGroup;
  loading = false;

  private readonly alphanumericRegex =
    '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$]*$';

  get controls() {
    return this.partnerForm?.controls;
  }

  constructor(
    private readonly bePartnerService: BePartnerService,
    private readonly toaster: ToastrService,
    private readonly translate: TranslateService,
    private readonly tokenInitService: TokenInitService
  ) {
    this.initForm();
  }

  initForm() {
    this.partnerForm = new FormGroup({
      merchantName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.alphanumericRegex),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(254),
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^5[0-9]{8}$'),
      ]),
    });
  }

  bePartner() {
    this.loading = true;

    this.tokenInitService.tokenInit().subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.data.token);

        const data = this.partnerForm?.value;

        this.bePartnerService
          .sendBePartnerFormLink({
            name: data.merchantName,
            email: data.email,
            mobileNumber: data.phoneNumber,
          })
          .subscribe({
            next: () => {
              this.handleSuccess();
            },
            error: (error) => {
              this.loading = false;
              this.handleError(error);
            },
          });
      },
    });
  }

  private handleSuccess() {
    this.partnerForm?.reset();
    this.loading = false;

    this.toaster.success(
      '',
      this.translate.instant('form-submitted-successfully')
    );
  }

  private handleError(error: any) {
    if (error.error?.errors) {
      const errors = error.error.errors;
      Object.keys(errors).forEach((key) => {
        const errorMessage = `${key}: ${errors[key].join(', ')}`;
        this.toaster.error(
          errorMessage,
          this.translate.instant('validation-error')
        );
      });
    } else {
      const errorMessage = error.error.detail || error.error.message;
      this.toaster.error(
        errorMessage,
        this.translate.instant('something-went-wrong')
      );
    }
  }

  openCallUsModal() {
    this.openModal.emit();
  }
}
