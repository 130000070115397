<div class="be-partner-full-page">
  <div class="container">
    <div class="join-us-widget d-flex">
      <div class="heading">
        <img
          loading="lazy"
          class="sparkles"
          src="assets/images/partner/sparkles.png"
          alt="sparkles"
        />

        <h1>{{ "we-introduce-you-and-increase-your-sales" | translate }}</h1>
        <p>
          {{ "we-introduce-you-and-increase-your-sales-desc" | translate }}
        </p>
      </div>

      <div class="form">
        <h3>{{ "lets-get-started" | translate }}</h3>
        <p class="subtitle">
          {{ "fill-your-business-info" | translate }}
        </p>

        <form *ngIf="bePartnerForm" [formGroup]="bePartnerForm">
          <section>
            <p class="section-title">
              {{ "merchant-information" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  maxlength="25"
                  type="text"
                  class="form-widget-input"
                  [placeholder]="('merchant-name' | translate) + ' *'"
                  formControlName="merchantName"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['merchantName']?.invalid &&
                    controls?.['merchantName']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['merchantName']?.invalid &&
                    controls?.['merchantName']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['merchantName']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['merchantName']?.errors?.['pattern']"
                    >
                      {{
                        "field-must-contains-only-letters-spaces-and-numbers"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  maxlength="50"
                  type="text"
                  class="form-widget-input"
                  [placeholder]="('brand-name' | translate) + ' *'"
                  formControlName="brandName"
                  [ngClass]="{
                  'invalid-input':controls?.['brandName']?.invalid && controls?.['brandName']?.touched
                }"
                />

                <div class="error-space">
                  <div
                    *ngIf=" controls?.['brandName']?.invalid && controls?.['brandName']?.touched"
                    class="error"
                  >
                    <span *ngIf="controls?.['brandName']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['brandName']?.errors?.['pattern']">
                      {{
                        "field-should-contains-chars-spaces-and-special-chars"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <ngx-select-dropdown
                  instanceId="brandType"
                  [config]="dropdownConfig"
                  [options]="brandTypes"
                  formControlName="brandType"
                ></ngx-select-dropdown>
                <div
                  *ngIf="controls?.['brandType']?.touched && controls?.['brandType']?.errors?.['required']"
                  class="error"
                >
                  {{ "required" | translate }}
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="
                    ('branches-number' | translate) +
                    (isOfflineBrand || isOnlineAndOfflineBrand ? ' *' : '')
                  "
                  formControlName="numberOfBranches"
                  appLeadingZero
                  minlength="0"
                  maxlength="4"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['numberOfBranches']?.invalid &&
                    controls?.['numberOfBranches']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['numberOfBranches']?.invalid &&
                    controls?.['numberOfBranches']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['numberOfBranches']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="
                      controls?.['numberOfBranches']?.errors?.['pattern']
                    "
                    >
                      {{ "branches-number-is-should-be-numbers" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  minlength="15"
                  maxlength="15"
                  type="text"
                  class="form-widget-input"
                  [placeholder]="
                    ('commercial-registration-number' | translate) + ' *'
                  "
                  formControlName="commercialRegistrationNumber"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['commercialRegistrationNumber']
                      ?.invalid &&
                    controls?.['commercialRegistrationNumber']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['commercialRegistrationNumber']
                      ?.invalid &&
                    controls?.['commercialRegistrationNumber']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['commercialRegistrationNumber']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['commercialRegistrationNumber']?.errors?.['minlength']"
                    >
                      {{
                        "min-length-numbers-error" | translate : { length: 15 }
                      }}
                    </span>
                    <span
                      *ngIf="controls?.['commercialRegistrationNumber']?.errors?.['pattern']"
                    >
                      {{ "number-field-error" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  minlength="3"
                  maxlength="20"
                  type="text"
                  class="form-widget-input"
                  [placeholder]="('sector' | translate) + ' *'"
                  formControlName="sector"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['sector']
                      ?.invalid &&
                    controls?.['sector']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['sector']
                      ?.invalid &&
                    controls?.['sector']
                      ?.touched
                  "
                    class="error"
                  >
                    <span *ngIf="controls?.['sector']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['sector']?.errors?.['minlength']">
                      {{ "min-length-chars-error" | translate : { length: 3 } }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  minlength="3"
                  maxlength="10"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="('salesVolume' | translate) + ' *'"
                  formControlName="salesVolume"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['salesVolume']
                      ?.invalid &&
                    controls?.['salesVolume']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['salesVolume']
                      ?.invalid &&
                    controls?.['salesVolume']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['salesVolume']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['salesVolume']?.errors?.['minlength']"
                    >
                      {{
                        "min-length-numbers-error" | translate : { length: 3 }
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  minlength="3"
                  maxlength="10"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="('average-sales' | translate) + ' *'"
                  formControlName="averageSales"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['averageSales']
                      ?.invalid &&
                    controls?.['averageSales']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['averageSales']
                      ?.invalid &&
                    controls?.['averageSales']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['averageSales']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['averageSales']?.errors?.['minlength']"
                    >
                      {{
                        "min-length-numbers-error" | translate : { length: 3 }
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  #activityStartDate
                  (focus)="activityStartDate.blur()"
                  type="text"
                  datepicker
                  format="YYYY-MM-DD"
                  formControlName="activityStartDate"
                  [placeholder]="('activity-start-date' | translate) + ' *'"
                  class="form-widget-input"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['activityStartDate']?.invalid &&
                    controls?.['activityStartDate']?.touched
                }"
                />

                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['activityStartDate']?.invalid &&
                    controls?.['activityStartDate']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="
                      controls?.['activityStartDate']?.errors?.['required']
                    "
                    >
                      {{ "required" | translate }}
                    </span>

                    <span
                      *ngIf="
                      controls?.['activityStartDate']?.errors?.['futureDate']
                    "
                    >
                      {{
                        "activity-start-date-should-not-be-in-future"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  maxlength="6"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="('number-of-employees' | translate) + ' *'"
                  formControlName="numberOfEmployees"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['numberOfEmployees']
                      ?.invalid &&
                    controls?.['numberOfEmployees']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['numberOfEmployees']
                      ?.invalid &&
                    controls?.['numberOfEmployees']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['numberOfEmployees']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  maxlength="6"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="('number-of-suppliers' | translate) + ' *'"
                  formControlName="numberOfSuppliers"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['numberOfSuppliers']
                      ?.invalid &&
                    controls?.['numberOfSuppliers']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['numberOfSuppliers']
                      ?.invalid &&
                    controls?.['numberOfSuppliers']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['numberOfSuppliers']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  minlength="3"
                  maxlength="10"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="('expected-sales-volume' | translate) + ' *'"
                  formControlName="expectedSalesVolume"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['expectedSalesVolume']
                      ?.invalid &&
                    controls?.['expectedSalesVolume']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['expectedSalesVolume']
                      ?.invalid &&
                    controls?.['expectedSalesVolume']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['expectedSalesVolume']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['expectedSalesVolume']?.errors?.['minlength']"
                    >
                      {{
                        "min-length-numbers-error" | translate : { length: 3 }
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="
                    ('website-link' | translate) +
                    (isOnlineBrand || isOnlineAndOfflineBrand ? ' *' : '')
                  "
                  formControlName="websiteLink"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['websiteLink']?.invalid &&
                    controls?.['websiteLink']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['websiteLink']?.invalid &&
                    controls?.['websiteLink']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['websiteLink']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['websiteLink']?.errors?.['pattern']"
                    >
                      {{ "enter-valid-url" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <p class="section-title">
              {{ "social-media-accounts-links" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="
                    ('facebook-link' | translate) +
                    (isOnlineBrand || isOnlineAndOfflineBrand ? ' *' : '')
                  "
                  formControlName="facebookLink"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['facebookLink']?.invalid &&
                    controls?.['facebookLink']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['facebookLink']?.invalid &&
                    controls?.['facebookLink']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['facebookLink']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['facebookLink']?.errors?.['pattern']"
                    >
                      {{ "enter-valid-url" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="
                    ('instagram-link' | translate) +
                    (isOnlineBrand || isOnlineAndOfflineBrand ? ' *' : '')
                  "
                  formControlName="instagramLink"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['instagramLink']?.invalid &&
                    controls?.['instagramLink']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['instagramLink']?.invalid &&
                    controls?.['instagramLink']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['instagramLink']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['instagramLink']?.errors?.['pattern']"
                    >
                      {{ "enter-valid-url" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="
                    ('x-link' | translate) +
                    (isOnlineBrand || isOnlineAndOfflineBrand ? ' *' : '')
                  "
                  formControlName="xLink"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['xLink']?.invalid &&
                    controls?.['xLink']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['xLink']?.invalid &&
                    controls?.['xLink']?.touched
                  "
                    class="error"
                  >
                    <span *ngIf="controls?.['xLink']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['xLink']?.errors?.['pattern']">
                      {{ "enter-valid-url" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <p class="section-title">
              {{ "store-rRepresentative-information" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  maxlength="20"
                  type="text"
                  class="form-widget-input"
                  [placeholder]="('relationship-to-store' | translate) + ' *'"
                  formControlName="relationshipToTheStore"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['relationshipToTheStore']?.invalid &&
                    controls?.['relationshipToTheStore']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['relationshipToTheStore']?.invalid &&
                    controls?.['relationshipToTheStore']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['relationshipToTheStore']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['relationshipToTheStore']?.errors?.['pattern']"
                    >
                      {{
                        "field-should-contains-chars-spaces-and-special-chars"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  maxlength="25"
                  type="text"
                  class="form-widget-input"
                  [placeholder]="('name' | translate) + ' *'"
                  formControlName="name"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['name']?.invalid &&
                    controls?.['name']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['name']?.invalid &&
                    controls?.['name']?.touched
                  "
                    class="error"
                  >
                    <span *ngIf="controls?.['name']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['name']?.errors?.['pattern']">
                      {{
                        "field-must-contains-only-letters-spaces-and-numbers"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="tel"
                  minlength="0"
                  maxlength="10"
                  class="form-widget-input"
                  [placeholder]="('id-number' | translate) + ' *'"
                  formControlName="idNumber"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['idNumber']?.invalid &&
                    controls?.['idNumber']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['idNumber']?.invalid &&
                    controls?.['idNumber']?.touched
                  "
                    class="error"
                  >
                    <span *ngIf="controls?.['idNumber']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['idNumber']?.errors?.['pattern']">
                      {{
                        "field-should-be-10-numbers-and-start-with-1-or-2"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  #dateOfBirth
                  type="text"
                  datepicker
                  format="YYYY-MM-DD"
                  formControlName="dateOfBirth"
                  [placeholder]="('birth-date' | translate) + ' *'"
                  class="form-widget-input"
                  (focus)="dateOfBirth.blur()"
                  [ngClass]="{
                    'invalid-input':
                      controls?.['dateOfBirth']?.invalid &&
                      controls?.['dateOfBirth']?.touched
                  }"
                />

                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['dateOfBirth']?.invalid &&
                    controls?.['dateOfBirth']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="
                      controls?.['dateOfBirth']?.errors?.['required']
                    "
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="
                      controls?.['dateOfBirth']?.errors?.['minimumAge']
                    "
                    >
                      {{ "age-should-be-18" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="tel"
                  minlength="9"
                  maxlength="9"
                  class="form-widget-input"
                  [placeholder]="('phone' | translate) + ' *'"
                  formControlName="phoneNumber"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['phoneNumber']?.invalid &&
                    controls?.['phoneNumber']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['phoneNumber']?.invalid &&
                    controls?.['phoneNumber']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['phoneNumber']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['phoneNumber']?.errors?.['pattern']"
                    >
                      {{ "phone-must-be-number" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <p class="section-title">
              {{ "documents-needed" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="commercialRegistration"
                  [isRequired]="true"
                >
                </app-file-picker>
              </div>
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="vatCertificate"
                  [isRequired]="true"
                >
                </app-file-picker>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="merchantBankAccount"
                  [isRequired]="true"
                >
                </app-file-picker>
              </div>
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="signatoriesPersonalId"
                  [isRequired]="true"
                >
                </app-file-picker>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="municipalityLicense"
                  [isRequired]="isOfflineBrand || isOnlineAndOfflineBrand"
                >
                </app-file-picker>
              </div>
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="eCommerceAuthenticationCertificate"
                  [isRequired]="isOnlineBrand || isOnlineAndOfflineBrand"
                >
                </app-file-picker>
              </div>
            </div>
          </section>

          <button
            [disabled]="bePartnerForm.invalid || loading"
            class="be-partner-button full"
            type="button"
            (click)="bePartnerFormMethod()"
          >
            {{ "confirm" | translate }}
            <img
              loading="lazy"
              src="assets/images/baby-blue-arrow-right.svg"
              alt="arrow"
            />
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<app-be-partner-registration-completed
  *ngIf="showSuccessModal"
></app-be-partner-registration-completed>
